export function experienceLinkGenerator(title) {
  return `/experiences/${removeSpecialCharactersAndCapitalLetters(title)}`
}

export function campaignLinkGenerator(title) {
  return `/campaigns/${removeSpecialCharactersAndCapitalLetters(title)}`
}

export function productLinkGenerator(title) {
  return `/producto/${removeSpecialCharactersAndCapitalLetters(title)}`
}


function removeSpecialCharactersAndCapitalLetters(word) {
  return word
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ +/g, "-")
    .toLowerCase()
}
